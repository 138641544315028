import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'src/app/_services/toaster.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssessmentResult, GaitCapabilityAssessmentDTO, GaitCapabilityAssessmentViewModelRead } from 'src/app/_models/generatedModels';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ExerciseToolTipModalComponent } from './exercise-tool-tip-modal.component';
import { getParamFromRoute } from 'src/app/_helpers/extensions.module';
import { BreadcrumbsService } from 'src/app/_services/breadcrumbs.service';
import { GaitCapabilityAssessmentProgramDialogComponent } from 'src/app/_components/assessment-program-assignment/gaitcapability-assessment-program-dialog.component';
import { FormCanDeactivate } from 'src/app/_guards/canDeactivate.guard';
import { AuthenticationService } from '../../../_services/authentication.service';

@Component({
  selector: 'add-edit-gc-assessment',
  templateUrl: 'add-edit-gaitcapacity-assessment.component.html',
  styleUrls: ['add-edit-gaitcapacity-assessment.component.scss']
})
export class AddEditGaitCapacityAssessmentComponent extends FormCanDeactivate implements OnInit {
  initialized = false;
  assessmentId: number;
  assessment: GaitCapabilityAssessmentViewModelRead;
  assessmentResult = AssessmentResult;
  public formGroup: FormGroup;
  submitComplete: Promise<{}> | undefined;
  clientUserId: number;

  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToasterService, private modalService: NgbModal, private assessmentService: AssessmentService, private auth: AuthenticationService,
    private fb: FormBuilder, private breadcrumbs: BreadcrumbsService) {
      super();
    }

  ngOnInit(): void {
    this.clientUserId = this.route.parent.parent.snapshot.params['id'] ?? this.auth.user.id;
    this.assessmentId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    this.breadcrumbs.SetSecondaryBreadcrumb((this.assessmentId ? 'Edit ' : 'Add ') + 'Assessment', this.router.url, []);

    if (this.assessmentId) {
      this.assessmentService.getGaitCapabitityAssessmentById(this.clientUserId, this.assessmentId).subscribe(result => {
        this.assessment = result;
        this.setupForm();
      });
    } else {
      this.setupForm();
    }
  }

  setupForm(): any {
    this.formGroup = this.fb.group({

      gaitCapabilityAssessmentBase: this.fb.group({
        // Fundamental
        functional: this.fb.group({
          toeTouch: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.functional?.toeTouch] || 'Able', [Validators.required]],

          rotationLeft: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.functional?.rotationLeft] || 'Able', [Validators.required]],
          balanceLeft: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.functional?.balanceLeft] || 'Able', [Validators.required]],
          hurdleLeft: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.functional?.hurdleLeft] || 'Able', [Validators.required]],

          rotationRight: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.functional?.rotationRight] || 'Able', [Validators.required]],
          balanceRight: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.functional?.balanceRight] || 'Able', [Validators.required]],
          hurdleRight: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.functional?.hurdleRight] || 'Able', [Validators.required]],
        }),

        // Mobility
      mobility: this.fb.group({
          ankleLeft: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.mobility?.ankleLeft] || 'Able', [Validators.required]],
          hamstringsLeft: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.mobility?.hamstringsLeft] || 'Able', [Validators.required]],
          hipLeft: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.mobility?.hipLeft] || 'Able', [Validators.required]],

          ankleRight: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.mobility?.ankleRight] || 'Able', [Validators.required]],
          hamstringsRight: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.mobility?.hamstringsRight] || 'Able', [Validators.required]],
          hipRight: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.mobility?.hipRight] || 'Able', [Validators.required]],
        }),

        // Stability
        stability: this.fb.group({
          coreLeft: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.stability?.coreLeft] || 'Able', [Validators.required]],
          hip: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.stability?.hip] || 'Able', [Validators.required]],
          calfLeft: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.stability?.calfLeft] || 'Able', [Validators.required]],
          footLeft: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.stability?.footLeft] || 'Able', [Validators.required]],

          coreRight: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.stability?.coreRight] || 'Able', [Validators.required]],
          calfRight: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.stability?.calfRight] || 'Able', [Validators.required]],
          footRight: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.stability?.footRight] || 'Able', [Validators.required]],
        }),

        // Foot
          foot: this.fb.group({
          bigToeLeft: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.foot?.bigToeLeft] || 'Able', [Validators.required]],
          splayLeft: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.foot?.splayLeft] || 'Able', [Validators.required]],
          pronationLeft: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.foot?.pronationLeft] || 'Able', [Validators.required]],

          bigToeRight: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.foot?.bigToeRight] || 'Able', [Validators.required]],
          splayRight: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.foot?.splayRight] || 'Able', [Validators.required]],
          pronationRight: [this.assessmentResult[this.assessment?.gaitCapabilityAssessmentBase?.foot?.pronationRight] || 'Able', [Validators.required]],
        })
      }),

      notes: [this.assessment?.notes || null]
    });

    this.formGroup.markFormDirtyOnValueChange().subscribe();
    this.initialized = true;
  }

  public onSave(isDraft: boolean) {
    if (!this.formGroup.valid) {
      this.formGroup.markAllControlsDirty();
      this.toastr.error('Please fill out all required fields', 'Error');
      return;
    }

    this.formGroup.markAsPristine();
    this.submitComplete = new Promise((resetButton:any, reject) => {
      const formData: GaitCapabilityAssessmentDTO = Object.assign({}, this.formGroup.value, this.formGroup.value);
      if (this.assessmentId) {
        this.update(formData, isDraft, resetButton);
      } else {
        this.save(formData, isDraft, resetButton);
      }
    });
  }

  showHelp(key: string) {
    const modalRef = this.modalService.open(ExerciseToolTipModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.key = key;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  save(formData: GaitCapabilityAssessmentDTO, isDraft: boolean, resetButton: () => any) {
    this.assessmentService.createGaitCapabilityAssessment(this.clientUserId, isDraft, formData).subscribe(
      (assessment) => {
        this.toastr.success('Assessment Saved', 'Success');
        if (!isDraft) {
          this.openAssessmentDialog(assessment.id);
        } else {
          this.navigateBack();
        }
        resetButton();
      },
      (error) => resetButton()
    );
  }

  update(formData: GaitCapabilityAssessmentDTO, isDraft: boolean, resetButton: () => any) {
    this.assessmentService.updateGaitCapabilityAssessment(this.assessmentId, isDraft, formData).subscribe(
      (assessment) => {
        this.toastr.success('Assessment Updated', 'Success');
        if (!isDraft) {
          this.openAssessmentDialog(assessment.id);
        } else {
          this.navigateBack();
        }
        resetButton();
      },
      (error) => resetButton()
    );
  }

  openAssessmentDialog(assessmentId: number) {
    const modalRef = this.modalService.open(GaitCapabilityAssessmentProgramDialogComponent, { size: 'lg' });
    modalRef.componentInstance.assessmentId = assessmentId;

    modalRef.result.then(
      (result) => {},
      (reason) => {
        this.navigateBack();
      }
    );
  }

  cancel() {
    this.navigateBack();
  }

  navigateBack() {
    if (this.assessmentId) {
      this.router.navigate(['../../../list'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../../list'], { relativeTo: this.route });
    }
  }
}
