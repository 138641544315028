import { Component, OnInit, Input} from '@angular/core';
import { Enums } from 'src/app/_models/generatedEnums';
import { AssessmentResult, AssessmentType, GaitCapabilityAssessmentViewModelRead, StringViewModel } from 'src/app/_models/generatedModels';
import { ActivatedRoute } from '@angular/router';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../_services/authentication.service';
import { getParamFromRoute } from '../../_helpers/extensions.module';
import { ExerciseToolTipModalComponent } from '../../coaches-admin/Clients/assessments/exercise-tool-tip-modal.component';
import { SettingsProvider } from '../../_services/settingsProvider.service';
import { ToasterService } from '../../_services/toaster.service';

@Component({
  selector: 'gaitcapability-assessment-result-details',
  templateUrl: 'gaitcapability-assessment-result-details.component.html',
  styleUrls: ['gaitcapability-assessment-result-details.component.scss']
})
export class GaitCapabilityAssessmentResultDetailsComponent implements OnInit {
  initialized = false;
  assessment: GaitCapabilityAssessmentViewModelRead;
  GaitCapabilityCategoryEnum = Enums.GaitCapabilityCategoryEnum;
  AssessmentResultEnum = Enums.AssessmentResultEnum;
  AssessmentResultShortEnum = Enums.AssessmentResultShortEnum;
  AssessmentResult = AssessmentResult;
  assessmentType = AssessmentType;
  clientUserId: number;
  programUrl: string = '/library/programs/view';
  gscProgramDescription: string;
  shareableLink: string;

  @Input()
  isCoachView: boolean = false;

  @Input()
  isShared: boolean = false;

  @Input()
  assessmentId: any;

  @Input()
  assessmentGuidId: any;

  constructor(private route: ActivatedRoute, private auth: AuthenticationService, private assessmentData: AssessmentService, private settingsProvider: SettingsProvider, private toastr: ToasterService, private modalService: NgbModal) {}

  ngOnInit(): void
  {
    // this view is used in two ways, so we need to check if the assessmentId was passed in
    if (!this.assessmentId) {

      if (this.isShared) {
        this.assessmentGuidId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;

        const model = new StringViewModel();
        model.value = this.assessmentGuidId;

        this.assessmentData.getGaitCapabitityAssessmentByGuid(model).subscribe((result) =>
        {
          this.assessment = result;

          this.gscProgramDescription = this.htmlToText(result?.program?.description);
          this.initialized = true;
        });

        return;
      }

      this.assessmentId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;
    }

    this.clientUserId = this.route.parent.parent.snapshot.params['id'] ?? this.auth.user.id;

    this.assessmentData.getGaitCapabitityAssessmentById(this.clientUserId, this.assessmentId).subscribe((result) =>
    {
      this.assessment = result;
      this.shareableLink = this.settingsProvider.siteUrl + '/gait-capability-assessment/' + this.assessment.uniqueGuidId;
      this.gscProgramDescription = this.htmlToText(result?.program?.description);
      this.initialized = true;
    });
  }

  onCopyLink()
  {
    //copy link to clipboard
    let link = document.createElement('textarea');
    link.value = this.shareableLink;
    document.body.appendChild(link);
    link.select();
    document.execCommand('copy');
    document.body.removeChild(link);
    this.toastr.success('Link copied to clipboard', 'Success');
  }

  showHelp(key: string)
  {
    const modalRef = this.modalService.open(ExerciseToolTipModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.key = key;
    modalRef.result.then(
      (result) => { },
      (reason) => { }
    );
  }

  htmlToText(html: string)
  {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }
}
