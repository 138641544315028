import { Observable, forkJoin } from 'rxjs';
import { ToasterService } from 'src/app/_services/toaster.service';
import { AssessmentService, ProgramsService } from 'src/app/_services/generatedServices';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { GaitReportViewModel } from '../../_models/generatedModels';

@Component({
  selector: 'gait-reports-comparison-dialog',
  templateUrl: 'gait-reports-comparison-dialog.component.html',
})
export class GaitReportComparisonDialogComponent implements OnInit {
  initialized = false;
  clientUserId: number;

  firstReport: GaitReportViewModel;
  secondReport: GaitReportViewModel;

  comparerUrl: string = './../gait-comparison/view';
  assessmentsUrl: string = './../analysis-walking/view';

  @Input()
  gaitReportIdsToCompare: number[];

  @Input()
  curentLocation: ActivatedRoute;

  @Input()
  userId: number;

  @Output()
  reportRemovedFromComparison = new EventEmitter<number>();

  constructor(private toastr: ToasterService, private assessmentService: AssessmentService, public activeModal: NgbActiveModal, private auth: AuthenticationService) { }

  ngOnInit(): void
  {
    let reportTasks = new Array<Observable<GaitReportViewModel>>();

    if (this.gaitReportIdsToCompare[0]) {
      reportTasks.push(this.assessmentService.getSpecificGaitReport(this.userId, this.gaitReportIdsToCompare[0]));
    }

    if (this.gaitReportIdsToCompare[1]) {
      reportTasks.push(this.assessmentService.getSpecificGaitReport(this.userId, this.gaitReportIdsToCompare[1]));
    }

    forkJoin(reportTasks)
      .subscribe((results) =>
      {
        if (results[0]) {
          this.firstReport = results[0];
        }

        if (results[1]) {
          this.secondReport = results[1];
        }

        this.initialized = true;
      });

    if (reportTasks.length == 0) {
      this.initialized = true;
    }
  }

  removeReportFromComparison(id: number)
  {
    if (this.firstReport && this.firstReport.id == id) {
      this.firstReport = null;
    }

    if (this.secondReport && this.secondReport.id == id) {
      this.secondReport = null;
    }


    this.reportRemovedFromComparison.emit(id);
  }
}
