import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import { CategoryScore, GaitReportDiagrams, GaitReportViewModel, StringViewModel } from 'src/app/_models/generatedModels';
import { ActivatedRoute } from '@angular/router';
import { AssessmentService } from 'src/app/_services/generatedServices';
import { SettingsProvider } from 'src/app/_services/settingsProvider.service';
import { ToasterService } from 'src/app/_services/toaster.service';
import { Enums } from '../../_models/generatedEnums';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GaitReportVideoPopupComponent } from './gait-report-video-popup.component';
import { GaitReportDiagramPopupComponent } from './gait-report-diagram-popup.component';
import { ExerciseToolTipModalComponent } from '../../coaches-admin/Clients/assessments/exercise-tool-tip-modal.component';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'gait-analysis-walking-result-details',
  templateUrl: 'gait-analysis-walking-result-details.component.html',
  styleUrls: ['gait-analysis-walking-result-details.component.scss']
})
export class GaitAnalysisWalkingResultDetailsComponent implements OnInit, OnDestroy
{
  initialized = false;
  analysisId: any;
  analysis: GaitReportViewModel;
  shareableLink: string;
  categoryScoreEnum = Enums.CategoryScoreEnum;
  gaitProgramPrimaryFactor = Enums.GaitProgramPrimaryFactorEnum;
  AssessmentResultShortEnum = Enums.AssessmentResultShortEnum;
  gaitScoreDescriptionEnum = Enums.GaitScoreDescriptionEnum;
  programUrl: string = '/library/programs/view';

  gscProgramDescription: string;
  groupedDiagrams: any;

  @Input()
  isCoachView: boolean = false;

  @Input()
  isShared: boolean = false;

  constructor(private route: ActivatedRoute, private assessmentData: AssessmentService, private settingsProvider: SettingsProvider, private toastr: ToasterService, private auth: AuthenticationService, private modalService: NgbModal) { }

  ngOnInit(): void
  {
    this.analysisId = this.route.snapshot.params ? this.route.snapshot.params['id'] : null;

    let pattern = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    let isGuid = this.analysisId.match(pattern) ? true : false;

    if (isGuid && this.isShared) {
      const model = new StringViewModel();
      model.value = this.analysisId;
      this.assessmentData.getSpecificGaitReportByGuid(model).subscribe((result) =>
      {
        this.analysis = result;
        this.analysis.guidId

        this.gscProgramDescription = this.htmlToText(result.gscProgram.description);
        this.groupedDiagrams = this.groupBy(this.analysis.diagrams, 'category');

        this.initialized = true;
      });
    } else {

      let clientUserId = this.route.parent.parent.snapshot.params['id'] ?? this.auth.user.id;

      this.assessmentData.getSpecificGaitReport(clientUserId, this.analysisId).subscribe((result) =>
      {
        this.analysis = result;
        this.shareableLink = this.settingsProvider.siteUrl + '/gait-walking/' + this.analysis.guidId;

        this.groupedDiagrams = this.groupBy(this.analysis.diagrams, 'category');

        if (result.gscProgram?.description) {
          this.gscProgramDescription = this.htmlToText(result.gscProgram.description);
        }

        this.initialized = true;
      });
    }
  }

  onCopyLink()
  {
    //copy link to clipboard
    let link = document.createElement('textarea');
    link.value = this.shareableLink;
    document.body.appendChild(link);
    link.select();
    document.execCommand('copy');
    document.body.removeChild(link);
    this.toastr.success('Link copied to clipboard', 'Success');
  }

  htmlToText(html: string)
  {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  showVideo()
  {
    const modalRef = this.modalService.open(GaitReportVideoPopupComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.analysisName = this.analysis.name;
    modalRef.componentInstance.videoLink = this.analysis.assetPath;
    modalRef.componentInstance.encoded = this.analysis.encoded;

    modalRef.result.then(
      (result) => { },
      (reason) => { }
    );
  }

  calculateScoringFactorPosition(categoryScore: CategoryScore, deviationPoints: number): number
  {
    if (deviationPoints == 0)
      return 50;

    let maxValue = 3;

    if (deviationPoints < 0) {
      let val = 50 - ((deviationPoints / -maxValue) * 45);

      return val >= 5 ? val : 5
    }

    let val = 50 + ((deviationPoints / maxValue) * 45);

    return val <= 95 ? val : 95
  }

  calculateScoringFactorPositionReverse(categoryScore: CategoryScore, deviationPoints: number): number
  {
    if (deviationPoints == 0)
      return 50;

    let maxValue = 3;

    if (deviationPoints > 0) {
      let val = 50 - ((deviationPoints / maxValue) * 45);

      return val >= 5 ? val : 5
    }

    let val = 50 + ((deviationPoints / -maxValue) * 45);

    return val <= 95 ? val : 95
  }

  
  showDiagram(diagram: GaitReportDiagrams)
  {
    const modalRef = this.modalService.open(GaitReportDiagramPopupComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.title = diagram.category + " - " + diagram.title;
    modalRef.componentInstance.url = diagram.url;

    modalRef.result.then(
      (result) => { },
      (reason) => { }
    );
  }

  showHelp(key: string)
  {
    const modalRef = this.modalService.open(ExerciseToolTipModalComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.key = key;
    modalRef.result.then(
      (result) => { },
      (reason) => { }
    );
  }

  groupBy(xs, key)
  {
    return xs.reduce(function (rv, x)
    {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  getNumberFromNumber(value: string)
  {
    if (!value)
      return 0;

    return parseInt(value);
  }

  ngOnDestroy(): void
  {
  }
}
